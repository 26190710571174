<template>
  <div class="px-5">
    <player-form
      title="Editar jugador"
      ref="playerForm"
      :deletable="true"
      :loading.sync="loading"
      :loadingRepresentatives.sync="loadingRepresentatives"
      @save="save"
      @toggleVisibility="toggleVisibility"
      @saveRepresentative="saveRepresentative"
      @deleteRepresentative="deleteRepresentative"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import PlayerForm from "./Form.vue"
export default {
  components: {
    PlayerForm
  },
  data() {
    return {
      loading: true,
      loadingRepresentatives: false,
      form: {
        photos: [{}]
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$refs.playerForm.form.id,
            status: !this.$refs.playerForm.visible ? 1 : 0
          },
          route: "/player",
          module: "player"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.playerForm.form
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.loading = true
          this.updatePlayerPhoto()
          this.$store
            .dispatch("global/update", {
              payload: {
                id: form.id,
                id_academy: form.id_academy,
                id_category: form.id_category,
                id_subcategory: form.id_subcategory,
                first_name: form.first_name,
                last_name: form.last_name,
                dni: form.dni,
                birthdate: form.birthdate,
                address: form.address,
                gender: form.gender,
                height: form.height,
                weight: form.weight,
                position: form.position,
                skillful_foot: form.skillful_foot,
                favorite_player: form.favorite_player,
                favorite_team: form.favorite_team,
                jersey_number: form.jersey_number,
                jersey_number: form.jersey_number,
                url_profile_picture: form.url_profile_picture,
                url_dni_picture: form.url_dni_picture
              },
              route: "/player/update",
              module: "player"
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification("master-update", response.code, this.$snotify)
              })
              if ([200, 201].includes(response.code))
                this.$router.push(
                  "/academias/" +
                    this.$route.params.id +
                    "/categorias/" +
                    this.$route.params.id2 +
                    "/jugadores"
                )
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    },

    saveRepresentative(index) {
      let representative = this.$refs.playerForm.form.representatives[index]
      if (!representative) return

      let validationKeys = Object.keys(representative)
      for (let i in validationKeys) {
        validationKeys[
          i
        ] = `representative_${representative.key}_${validationKeys[i]}`
      }
      delete validationKeys.key

      this.$validator.validateAll(validationKeys).then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!representative.url_dni_picture)
            return this.$snotify.error(
              "La foto de la cédula del representante es obligatoria",
              "Error",
              {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                titleMaxLength: 40,
                bodyMaxLength: 100,
                position: "rightBottom"
              }
            )
          this.loadingRepresentatives = true
          if (representative.id) {
            this.$store
              .dispatch("global/update", {
                payload: {
                  id: representative.id,
                  type: representative.type,
                  first_name: representative.first_name,
                  last_name: representative.last_name,
                  email: representative.email,
                  dni: representative.dni,
                  address: representative.address,
                  profession: representative.profession,
                  workplace: representative.workplace,
                  phone: representative.phone,
                  local_phone: representative.local_phone,
                  url_dni_picture: representative.url_dni_picture
                },
                route: "/representative/update"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification("master-update", response.code, this.$snotify)
                })
                this.loadingRepresentatives = false
              })
              .catch((error) => {
                this.loadingRepresentatives = false
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          } else {
            this.$store
              .dispatch("global/create", {
                payload: {
                  type: representative.type,
                  id_player: this.$route.params.id,
                  first_name: representative.first_name,
                  last_name: representative.last_name,
                  email: representative.email,
                  dni: representative.dni,
                  address: representative.address,
                  profession: representative.profession,
                  workplace: representative.workplace,
                  phone: representative.phone,
                  local_phone: representative.local_phone,
                  url_dni_picture: representative.url_dni_picture
                },
                route: "/representative/save"
              })
              .then((response) => {
                this.loadingRepresentatives = false
                notify().then(({ notification }) => {
                  notification("master", response.code, this.$snotify)
                })
              })
              .catch((error) => {
                console.log("representative creation error:", error)
                this.loadingRepresentatives = false
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        }
      })
    },
    deleteRepresentative(index) {
      if (this.$refs.playerForm.form.representatives.length === 1) {
        return this.$snotify.error(
          "No se pueden eliminar todos los representantes",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      if (!this.$refs.playerForm.form.representatives[index].id) {
        this.$refs.playerForm.form.representatives.splice(index, 1)
        return
      }
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al representante.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$refs.playerForm.form.representatives[index].id,
                  status: 2
                },
                route: "/representative"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200)
                  this.$refs.playerForm.form.representatives.splice(index, 1)
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    },
    updatePlayerPhoto() {
      for (let element of this.$refs.playerForm.form.photos) {
        if (element.id) {
          this.$store
            .dispatch("global/update", {
              payload: {
                id: element.id,
                url_photo: element.url_photo
              },
              route: "/player_photo/update"
            })
            .catch((error) => {
              this.loading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        } else {
          this.$store
            .dispatch("global/create", {
              payload: {
                id_player: this.$route.params.id,
                type_photo: element.type_photo,
                url_photo: element.url_photo
              },
              route: "/player_photo/save"
            })
            .catch((error) => {
              console.log("player photo save error:", error)
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      }
    },
    deletePlayer() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente al jugador.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.$refs.playerForm.form.id,
                  status: 2
                },
                route: "/player",
                module: "player"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200)
                  this.$router.push(
                    "/academias/" +
                      this.$route.params.id +
                      "/categorias/" +
                      this.$route.params.id2 +
                      "/jugadores"
                  )
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/player",
          module: "player",
          id: this.$route.params.id3
        })
        .then((response) => {
          this.form = response.data
          if (this.form.id_category === 0) this.form.id_category = null
          if (this.form.photos.length === 0)
            this.form.photos.push({
              id_player: this.$route.params.id,
              type_photo: 3,
              url_photo: null
            })
          for (let index in this.form.representatives)
            this.form.representatives[index].key =
              this.form.representatives[index].id

          this.$refs.playerForm.form = this.form
          this.$refs.playerForm.visible =
            response.data && response.data.active === 1 ? true : false
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
